import React, { FC } from 'react'
import { NewsletterComponent } from '../components'

interface Props {
  title: string
  description: string
}

const NewsletterContainer: FC<Props> = ({ title, description }) => (
  <NewsletterComponent title={title} description={description} />
)

export default NewsletterContainer
